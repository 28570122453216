body {
    background-color: #f4f3ef;
    -webkit-font-smoothing: antialiased;
    color: #3c4257;
    font-weight: 400;
    font-size: 14px;
    font-family: -apple-system , BlinkMacSystemFont , "Segoe UI" , "Roboto" , "Helvetica Neue" , "Ubuntu" , sans-serif;
}

a {
  text-decoration: none;
  color: #788eec;
  font-weight: 500;
}

h4 {
  font-size: 1.714em;
  line-height: 1.45em;
  margin: 1em 0;
}

.PrimaryButton {
  background-color: #788eec;
  border-radius: 4px;
  font-size: 14px;
  font-stretch: 100%;
  font-weight: 500;
  font-family: -apple-system , BlinkMacSystemFont , "Segoe UI" , "Roboto" , "Helvetica Neue" , "Ubuntu" , sans-serif;
  color: #f4f3ef;
  cursor: pointer;
  border: 0px;
}

.App {
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.Link {
  color: #788eec;
  text-decoration: none;
}

.MainMenu {
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 300px;
  left: 0;
  z-index: 999;
  top: 0;
}

.MainMenu:before, .MainMenu:after {
  position: absolute;
  content: "";
  opacity: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
}

.MainMenu:after {
  background: #66615b;
  background: -webkit-gradient(linear,left top,left bottom,from(#66615b),color-stop(80%,#000));
  background: linear-gradient(#66615b,#000 80%);
  z-index: 3;
}

.MenuContainer {
  position: relative;
  height: calc(100vh - 75px);
  overflow: auto;
  z-index: 4;
  padding-bottom: 100px;
}

.MainPanel {
  padding: 30px 30px 100px 30px;
  position: relative;
  float: right;
  width: calc(100% - 360px);
}

.Card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0,0,0,.15);
  background-color: #fff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0;
  -webkit-transition: transform .3s cubic-bezier(.34,2,.6,1),box-shadow .2s ease;
  -webkit-transition: box-shadow .2s ease,-webkit-transform .3s cubic-bezier(.34,2,.6,1);
  transition: box-shadow .2s ease,-webkit-transform .3s cubic-bezier(.34,2,.6,1);
  transition: transform .3s cubic-bezier(.34,2,.6,1),box-shadow .2s ease;
  transition: transform .3s cubic-bezier(.34,2,.6,1),box-shadow .2s ease,-webkit-transform .3s cubic-bezier(.34,2,.6,1);
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  padding: 20px 30px;
}

.CardHeader {
}

.TableContainer {
  margin-bottom: 1rem;
}

.TableContainer td span {
  color: #212529;
}

.TableContainer .table {
  border-collapse: collapse;
}

.TableContainer td, .TableContainer th {
  padding: 12px 7px;
  vertical-align: middle;
  text-align: left;
}

.TableContainer th {
  color: #788eec;
  text-transform: uppercase;
  font-weight: bold;
}

.TableContainer td {
  border-top: 1px solid #dee2e6;
  box-sizing: border-box;
}

.Table {
  width: 100%;
  table-layout: fixed;
}

.text-center {
  text-align: center !important;
}

.sweet-loading {
  text-align: center;
}

.spinner-container {
  margin: 20px auto;
  display: inline-block;
  width: 50px;
}

.dx-g-bs4-table-edit-cell input {
  font-size: 16px;
  height: 25px;
  width: 100%;
}

.MenuItemsList li a p {
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  font-size: 0.8rem;
}

.MenuItemsList li > a {
  margin: 0px 15px 0;
  color: #fff;
  display: block;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  padding: 0px 8px;
  line-height: 30px;
  opacity: .7;
  font-size: 0.8rem;
}

.MenuItemsList ul li, .MenuItemsList li {
  display: block;
  width: 100%;
}

.btn.btn-icon:not(.btn-footer) .fa, .btn.btn-icon:not(.btn-footer) .far, .btn.btn-icon:not(.btn-footer) .fas, .btn.btn-icon:not(.btn-footer) .nc-icon, .navbar .navbar-nav>a.btn.btn-icon:not(.btn-footer) .fa, .navbar .navbar-nav>a.btn.btn-icon:not(.btn-footer) .far, .navbar .navbar-nav>a.btn.btn-icon:not(.btn-footer) .fas, .navbar .navbar-nav>a.btn.btn-icon:not(.btn-footer) .nc-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-12px,-12px);
  transform: translate(-12px,-12px);
  line-height: 1.5626rem;
  width: 24px;
}

.btn.btn-icon.btn-sm .fa, .btn.btn-icon.btn-sm .far, .btn.btn-icon.btn-sm .fas, .btn.btn-icon.btn-sm .nc-icon, .navbar .navbar-nav>a.btn.btn-icon.btn-sm .fa, .navbar .navbar-nav>a.btn.btn-icon.btn-sm .far, .navbar .navbar-nav>a.btn.btn-icon.btn-sm .fas, .navbar .navbar-nav>a.btn.btn-icon.btn-sm .nc-icon {
  font-size: .6875rem;
}

.btn i, .navbar .navbar-nav>a.btn i {
  margin-right: 3px;
}

.btn-sm {
  font-size: .8571em;
  border-radius: 3px;
  padding: 5px 15px;
}

.btn.btn-icon, .navbar .navbar-nav>a.btn.btn-icon {
  height: 2.375rem;
  min-width: 2.375rem;
  width: 2.375rem;
  padding: 0;
  font-size: .9375rem;
  overflow: hidden;
  position: relative;
  line-height: normal;
}

.btn.btn-icon.btn-sm, .navbar .navbar-nav>a.btn.btn-icon.btn-sm {
  height: 1.875rem;
  min-width: 1.875rem;
  width: 1.875rem;
}

.btn, .navbar .navbar-nav>a.btn {
  font-weight: 600;
  font-size: .8571em;
  line-height: 1.35em;
  text-transform: uppercase;
  border: none;
  margin: 10px 1px;
  border-radius: 3px;
  padding: 11px 22px;
  cursor: pointer;
  background-color: #66615b;
  color: #fff;
  -webkit-transition: all .15s linear;
  transition: all .15s linear;
}

.btn-success {
  background-color: #6bd098;
  color: #fff;
}

.btn-danger {
  background-color: #ef8157;
  color: #fff;
}

.btn-info {
  background-color: #51bcda;
  color: #fff;
}

.select-dropdown {
  height: 30px;
  width: 100%;
  padding: 5px 8px;
  font-size: 14px;
}

button:disabled {
  background-color: grey;
  cursor: not-allowed !important;
  opacity: 0.6;
}